import React, {Component} from 'react';
import styled from 'styled-components'
import {Container} from 'reactstrap'
import MusicPlayer from "components/shared/MusicPlayer"

class MusicPlayerSection extends Component {
    render() {
        return (
            <Container fluid={true} className="narrow">
                <Container>
                    <p className="font-weight-bold">The truly remarkable thing about FROZEN is that in a musical that features a song as poignant as ‘Do You Want to Build a Snowman?’, as uplifting as ‘For the First Time in Forever’, or as funny as ‘In Summer’, there is one song that towers above them all, glittering like a freshly made ice palace.</p>

                    <p>We are talking, of course, about ‘Let It Go’, the song which, when FROZEN was released in the winter of 2013, became a sensation among anyone with ears. It reached the top five in the Billboard Hot 100 chart, sold 10.9 million copies in a year and won the Academy® Award for Best Original Song. Adults loved it, but it was a track that bewitched children – causing them to drive their parents to the brink of insanity by obsessively playing it over and over again.</p>

                    <p>It was the kind of magic about which pop song producers could only dream. After all, if it was that easy to make a catchy song, then everyone would be doing it. But what made ‘Let It Go’ work where so many others have not? What is the secret to its sorcery? We asked two music experts for their thoughts.</p>

                    <MusicPlayer/>
                </Container>
            </Container>
        );
    }
}

export default MusicPlayerSection;