import React, { Component } from "react"
import ReactPlayer from 'react-player'
import styled, { css } from "styled-components"
import { Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons"
import { media } from "utils/Media"
import playerBgXs from "images/bg-musicplayer-xs.svg"
import playerBg from "images/bg-musicplayer.svg"
import song1 from "music/LetItGo.mp3"

const ReactPlayerStyled = styled(ReactPlayer)`
    position: absolute;
    max-width: 100%;
    opacity: 0;
    z-index: 0;
`

const PlayerWrap = styled.div`
    position: relative;
    padding: 1rem;
    height: 115px;
    width: 342px;
    max-width: 100%;
    margin: 0 auto;
    background-image: url("${playerBgXs}");
    background-size: 100% 100%;

    @media ${media.md} {
        background-image: url("${playerBg}");
        background-size: 100% 100%;
        padding: 3rem;
        max-width: 740px;
        height: initial;
        width: initial;
    }
`

const PlayButton = styled.button`
    background-color: transparent;
    border: 1px solid ${props => props.theme.colors.navy};
    border-radius: 50%;
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 1;
    color: ${props => props.theme.colors.navy};
    font-size: 1.5rem;
    height: 35px;
    width: 35px;
    padding: 0;
    
    @media ${media.md} {
        position: relative;
        bottom: initial;
        left: initial;
        height: 57px;
        width: 57px;
    }

    svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12px;
        height: 12px;
        
        @media ${media.md} {
            width: 30px;
            height: 30px;
        }
    }

    @media ${media.md} {
        height: 77px;
        width: 77px;
    }
`

const SongText = styled.div`
    font-size: .8rem;
    line-height: 120%;

    @media ${media.md} {
        font-size: 1.25rem;
    }


    ${props => props.bold && css`
        font-family: ${props => props.theme.font.family.bold};
        font-weight: bold;
    `}

`

const SongProgress = styled.input`
    width: calc(100% - 80px);
    background-color: white;
    height: 10px;
    -webkit-appearance: none;
    margin-top: .5rem;
    position: absolute;
    bottom: 28px;
    left: 60px;
    
    @media ${media.md} {
        position: relative;
        left: initial;
        bottom: initial;
        margin-left: 0;
        width: 100%;
        height: 10px;
    }

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 14px;
        cursor: pointer;
        animate: 0.2s;
        border-radius: 14px;
    }

    &::-webkit-slider-thumb {
        margin-top: 1px;
        height: 11px;
        width: 40px;
        border-radius: 0;
        background: ${props => props.theme.colors.blue};
        cursor: pointer;
        -webkit-appearance: none;
    }

    &::-moz-range-track {
        width: 100%;
        height: 14px;
        cursor: pointer;
        animate: 0.2s;
        border-radius: 14px;
    }
    
    &::-moz-range-thumb {
        margin-top: 1px;
        height: 11px;
        width: 40px;
        border-radius: 0;
        border: 0;
        background: ${props => props.theme.colors.blue};
        cursor: pointer;
        -webkit-appearance: none;
    }

    /* &::-ms-track {
        width: 100%;
        height: 14px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: #50555C;
        border: 0px solid #000000;
        border-radius: 28px;
        box-shadow: 1px 1px 1px #50555C;
    }

    &::-ms-fill-upper {
        background: #50555C;
        border: 0px solid #000000;
        border-radius: 28px;
        box-shadow: 1px 1px 1px #50555C;
    }

    &::-ms-thumb {
        margin-top: 1px;
        box-shadow: 0px 0px 0px #000000;
        border: 0px solid #000000;
        height: 20px;
        width: 40px;
        border-radius: 12px;
        background: #529DE1;
        cursor: pointer;
    }

    &:focus::-ms-fill-lower {
        background: #50555C;
    }

    &:focus::-ms-fill-upper {
        background: #50555C;
    } */

`

class MusicPlayer extends Component {

    constructor(props) {
        super(props)

        this.state = {
            songs: [
                {
                    name: "Let It Go (From FROZEN: The Broadway Musical)",
                    artist: "Caissie Levy",
                    file: song1
                }
            ],
            activeSong: 0,
            pip: false,
            controls: false,
            light: false,
            volume: 0.8,
            muted: false,
            played: 0,
            loaded: 0,
            duration: 0,
            playbackRate: 1.0,
            loop: false,
            collapse: false,
            percentage: 0
        }

        this.toggleAudioPlaying = this.toggleAudioPlaying.bind(this)
        // this.changeSong = this.changeSong.bind(this)
    }

    toggleAudioPlaying() {
        this.setState({
            playing: !this.state.playing
        })
    }

    handleSeekMouseDown = e => {
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        this.setState({ played: parseFloat(e.target.value) })
    }

    handleSeekMouseUp = e => {
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))
    }

    // changeSong(direction) {
    //     const { songs, activeSong } = this.state
    //     const songCount = songs.length - 1
    //     let nextSong

    //     if (direction) {
    //         //Next
    //         nextSong = activeSong + 1
    //         nextSong = nextSong > songCount ? 0 : nextSong
    //     } else {
    //         //prev
    //         nextSong = activeSong - 1
    //         nextSong = nextSong < 0 ? songCount : nextSong
    //     }

    //     this.setState({
    //         activeSong: nextSong
    //     })
    // }

    handleProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            // { played: 0.12, playedSeconds: 11.3, loaded: 0.34, loadedSeconds: 16.7 }
            this.setState(state)
        }
    }

    ref = player => {
        this.player = player
    }

    render() {
        const { songs, activeSong, playing, played, playbackRate } = this.state

        const config = {
            file: {
                debug: true,
                forceAudio: true,
                attributes: {
                    disablePictureInPicture: true
                }
            }
        }

        return (
            <PlayerWrap>
                <ReactPlayerStyled
                    ref={this.ref}
                    url={songs[activeSong].file}
                    config={config}
                    controls
                    playbackRate={playbackRate}
                    onProgress={this.handleProgress}
                    playing={playing}
                    // onBufferEnd={() => this.setState({ playing: true })}
                    width="100%"
                    height="100px"
                    // muted={!playing}
                    // onError={this.handleError}
                />

                <div className="d-md-flex align-items-center">
                    <div className="text-center pr-4">
                        <PlayButton onClick={this.toggleAudioPlaying}>
                            {playing ? (
                                <FontAwesomeIcon icon={faPause} />
                            ) : (
                                <FontAwesomeIcon icon={faPlay} />
                            )}
                        </PlayButton>
                    </div>
                    <div>
                        <SongText bold>{songs[activeSong].name}</SongText>
                        <SongText>{songs[activeSong].artist}</SongText>
                        <SongProgress
                            type="range"
                            min={0}
                            max={1}
                            step="any"
                            value={played}
                            onMouseDown={this.handleSeekMouseDown}
                            onChange={this.handleSeekChange}
                            onMouseUp={this.handleSeekMouseUp}
                        />
                    </div>
                </div>
            </PlayerWrap>
        )
    }
}

export default MusicPlayer