import React, {Component} from "react"
import styled from 'styled-components'
import {Container} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby'

import Layout from "components/Layout/Layout"
import TurningPoint from "components/LetItGoPage/TurningPoint"
import MusicalTheatre from "components/LetItGoPage/MusicalTheatre"
import PerfectMarriage from "components/LetItGoPage/PerfectMarriage"
import LetItGoSong from "components/LetItGoPage/LetItGoSong"
import Quote from 'components/shared/Quote'
import MusicPlayerSection from "components/LetItGoPage/MusicPlayerSection"
import VideoModal from "components/shared/VideoModal"
import FindOutMore from "components/shared/FindOutMore"
import BodymovinHeader from "components/shared/BodymovinHeader"


import {media} from "utils/Media"

const scrollToElement = require('scroll-to-element')

const EffectivePopSong = styled.div`
  position: relative;
  margin: -4rem 0 0;
  padding-top: 1rem;

  @media ${media.sm} {
    background: url(${props => props.cloudsSm}) no-repeat left top;
    background-size: contain;
    padding-top: 4rem;
  }

  @media ${media.md} {
    background: url(${props => props.cloudsLg}) no-repeat left top;
    background-size: contain;
  }

`
const Query = () => (
  <StaticQuery
    query={graphql`
            query {
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                CloudsSm: file(relativePath: { eq: "Article1/clouds-top-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                CloudsLg: file(relativePath: { eq: "Article1/clouds-top-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                videoPlaceholder1: file(relativePath: { eq: "coming-to-london-this-october.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
                
                videoPlaceholder2: file(relativePath: { eq: "making-frozen-the-musical-thumb.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, quality: 100)
                    }
                }
            }
		`}
    render={data => (
      <IndexPage data={data}/>
    )}
  />
)

class IndexPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false,
      src: "https://secure.disney.com/embed/58c27d0acc3c9c895734fa28?domain=www.disney.co.uk"
    }
  }
  
  toggleModal = (e, title, src) => {
    e.preventDefault();
    
    this.setState({
      src: src,
      title: title,
      modalOpen: !this.state.modalOpen
    });
  }
  
  onClick = () => {
    scrollToElement('#bottomOfIframe', {
      duration: 300
    });
  }
  
  render() {
    
    return (
      <Layout pageName="let-it-go" slug="let-it-go" title="What makes 'Let It Go' so catchy"
              description="Let It Go: Learn what makes ‘Let It Go’ so catchy before Frozen the Musical comes to London’s Theatre Royal Drury Lane from August 2021">
        <BodymovinHeader/>
        
        <MusicPlayerSection/>
        
        <MusicalTheatre/>
        
        <EffectivePopSong
          cloudsSm={this.props.data.CloudsSm.childImageSharp.gatsbyImageData.images.fallback.src}
          cloudsLg={this.props.data.CloudsLg.childImageSharp.gatsbyImageData.images.fallback.src}
        >
          <Container fluid={true} className="narrow">
            <Container>
              <Quote className="my-5">
                <div className="quoteContent">
                  <h3>An effective pop song has to feel familiar the first time you hear it.</h3>
                  <p className="color--navy">Dr Vasco Hexel</p>
                </div>
              </Quote>
              
              <p>One theory for the incredible catchiness of ‘Let It Go’ is that it’s a musical number that owes more to
                pop music than to Broadway.</p>
              
              <p>“‘Let It Go’ is basically a hit single that we get in the middle of a Disney film,” says Dr Vasco
                Hexel, who teaches composition for screen at the Royal College of Music. “It’s not as lush or as
                complicated as a musical theatre piece might otherwise be. The structure is quite streamlined so you get
                a very clear sense of verse / chorus / verse / chorus and that’s what makes pop songs so catchy – the
                immediate accessibility. They’re immediately catchy because there's an economy of style – there is so
                little material, but in the best possible way. An effective pop song has to feel familiar the first time
                you hear it.”</p>
              
              <p>Yet while it’s true that ‘Let It Go’ shares similarities with pop music, its success cannot simply be
                attributed to the fact that it sounds like a pop song. Instead, its power lies in occupying a space
                somewhere between pop and musical theatre.</p>
              
              <Quote className="my-5">
                <div className="quoteContent">
                  <h3>You can’t ignore the strong musical theatre credentials.</h3>
                  <p className="color--navy">Dr Christopher Wiley </p>
                </div>
              </Quote>
              
              <p>“It eludes classification,” says Dr Christopher Wiley, senior lecturer in music at the University of
                Surrey. “It does owe a lot to pop music, but you can’t ignore the strong musical theatre credentials.
                It’s written by Robert Lopez, who has worked on Avenue Q and The Book of Mormon, and his wife Kristen
                Anderson-Lopez. We’ve also got Idina Menzel singing it, who is well known to musical theatre audiences
                from Rent and Wicked. ”</p>
              
              <p>“It is unusual for a Disney song,” he adds, “because it does have this journey but starts very bleakly,
                in a minor key. It sounds like it’s going to be a piano ballad, like it’s going to be a quiet,
                reflective number. It doesn’t sound like the kind of thing that might close the first half of a musical
                theatre show and leave the audience singing it during the break.”</p>
            
            </Container>
          </Container>
        </EffectivePopSong>
        
        <LetItGoSong/>
        
        <PerfectMarriage/>
        
        <TurningPoint/>
        
        <div className="mt-xl-5">
          <FindOutMore title="Discover more"/>
        </div>
        
        <VideoModal toggleModal={this.toggleModal} title={this.state.title} src={this.state.src}
                    modalOpen={this.state.modalOpen}/>
      
      </Layout>
    )
  }
}

export default Query