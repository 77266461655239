import React, {Component} from 'react';
import styled from 'styled-components'
import {Container} from 'reactstrap'
import MusicPlayer from "components/shared/MusicPlayer"
import Lottie from 'react-lottie'
import * as animationData576 from 'bodymovin/lyrics-let-it-go-576x400/data.json'
import * as animationData1200 from 'bodymovin/lyrics-let-it-go-1200x375/data.json'

const LetItGoSongWrapper = styled.div`
    pointer-events: none;
`

class LetItGoSong extends Component {
    render() {
        const letItGo576 = {
            loop: true,
            autoplay: true,
            renderer: 'svg',
            animationData: animationData576.default,
            assetsPath: "/images/"
        };

        const letItGo1200 = {
            loop: true,
            autoplay: true,
            renderer: 'svg',
            animationData: animationData1200.default,
            assetsPath: "/images/"
        };
        return (
            <LetItGoSongWrapper>
                <Container fluid={true} className="narrow">
                    <Container>
                        <p className="font-weight-bold">A key ingredient of its catchiness is in the simplicity of the chorus:</p>

                        <div className="d-block d-md-none">
                            <Lottie options={letItGo576}
                                    className="img-fluid"
                            />
                        </div>
                        <div className="d-none d-md-block">
                            <Lottie options={letItGo1200}
                                    className="img-fluid"
                            />
                        </div>

                        <p>“The lyrics couldn’t be simpler,” says Hexel. “‘Let it go! Let it go!’ – it’s so concise and so easy to remember. And it has this three-note motif that the three syllables sit on that, again, is so simple. It’s so small and yet so perfect, and is repeated often.</p>

                        <p>“Listeners look for hooks that they can latch on to and the chorus of ‘Let it Go’ gives them that,” says Wiley. “But there are other lyrics in the song that are actually very sophisticated and complex for a film that’s probably pitched at a young audience. Take ‘my soul is spiralling in frozen fractals all around’, from the bridge, which is very different from the likes of The Lion King’s ‘Can You Feel the Love Tonight?’, where lyrics like ‘believe the very best’ are very easy to comprehend. I can imagine kids up and down the country asking their parents what a frozen fractal is.”</p>
                    </Container>
                </Container>
            </LetItGoSongWrapper>
        );
    }
}

export default LetItGoSong;