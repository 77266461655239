import React from 'react'
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {Row, Col, Container} from 'reactstrap'
import ReactCompareImage from 'react-compare-image'
import Quote from 'components/shared/Quote'
import {media} from "utils/Media"

const TurningPoint = styled.div`
    position: relative;
    margin: -4rem 0 8rem;
    padding-top: 1rem;
    
    @media ${media.sm} {
        background: url(${props => props.cloudsSm}) no-repeat left bottom;
        background-size: contain;
        margin: -4rem 0 6rem;
        padding-top: 4rem;
    }    
    
    @media ${media.md} {
        background: url(${props => props.cloudsLg}) no-repeat left bottom;
        background-size: contain;
        margin: -4rem 0 5rem;
    }    
    
    @media ${media.lg} {
        margin: -4rem 0 -6rem;
    }    
    
    @media ${media.xl} {
        margin: -4rem 0 -12rem;
    }    
    
    .quoteWrapper {
        margin: 3rem 0;
        
        @media ${media.xl} {
            margin-top: 15rem;
        }
    }
    
    .compareImageWrapper {
    margin: 3rem 0;
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                CloudsSm: file(relativePath: { eq: "Article1/clouds-top-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                CloudsLg: file(relativePath: { eq: "Article1/clouds-top-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
               
                Image1: file(relativePath: { eq: "LetItGo/lig-elsa-monster-lineup.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 768, quality: 100)
                    }
                }
                
                Image2: file(relativePath: { eq: "LetItGo/lig-elsa-letitgo-lineup.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 768, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <TurningPoint
                cloudsSm={data.CloudsSm.childImageSharp.gatsbyImageData.images.fallback.src}
                cloudsLg={data.CloudsLg.childImageSharp.gatsbyImageData.images.fallback.src}
            >
                <Container fluid={true}>
                    <Container>
                        <Row className="d-none d-xl-flex">
                            <Col xs={12} xl={6}>
                                <p className="font-weight-bold">One of the most striking things about ‘Let It Go’ is how successfully it has managed to stand on its own – how it has functioned, more or less, like a pop single. But would ‘Let It Go’ really be the cultural phenomenon it is if it was released in a universe where FROZEN didn’t exist? Where the song arrived without the context of Elsa and her great awakening, without her blue dress, power plait and giant ice palace? Our experts don’t think so.</p>

                                <p>“The way that the song goes with the film is very powerful,” says Wiley. “You see Elsa flinging her arms around, making ice come out of her hands and stamping on the floor and the floor becomes a snowflake. It’s the mirroring of the images and music – it cements it in our minds.</p>

                                <p>“It’s also the placement in the film. It’s the halfway point, it’s the turning point for Elsa. It’s part of her journey as a character. It’s just perfect for the final song of the first half of a musical theatre piece. It’s like ‘Defying Gravity’ in Wicked. It’s got that kind of power and strength to it.”</p>

                                <p>Hexel agrees.</p>

                                <p>“We’re getting this breakaway moment that suddenly feels oddly hopeful,” he says, “even though perhaps something quite bad and dark is happening. Yet it feels so empowering. From a storytelling perspective – and songwriters are storytellers – that moment is so well judged to say ‘we’re shining the spotlight on this character who is becoming so much more than we thought she could be’, which parallels nicely with her own experience of self-discovery. She’s saying ‘I’m finally going to be who I’m meant to be and I’m going to embrace who I am.’</p>

                                <p>“It must have been so tough for [Anderson-Lopez and Lopez] to figure out how to portray this complex character as somebody relatable. How does it feel to be outcast? How does it feel to be suddenly all on your own and yet find power within that? And to put that into music is a daunting task. I don’t know how many versions they would have gone through. What they’ve ended up with, though, is this hit song that encapsulates that moment so effectively. It’s something that’s difficult to plan. I’m a composer, right? And if I had to sit down and try to do that... It doesn’t just happen. A lot of stars have to align.”</p>

                            </Col>
                            <Col xs={12} xl={6} className="my-auto">
                                <ReactCompareImage sliderLineColor="#8AECF5" sliderLineWidth={1} handleSize={0} sliderPositionPercentage={0.5} hover={true} leftImage={data.Image1.childImageSharp.gatsbyImageData.images.fallback.src} rightImage={data.Image2.childImageSharp.gatsbyImageData.images.fallback.src} />
                                <div className="quoteWrapper">
                                    <Quote>
                                        <div className="quoteContent">
                                            <h3>It’s also the placement in the film. It’s the halfway point, it’s the turning point for Elsa.</h3>
                                            <p className="color--navy">Dr Christopher Wiley </p>
                                        </div>
                                    </Quote>
                                </div>
                            </Col>
                        </Row>
                        <div className="d-block d-xl-none">
                            <p className="font-weight-bold">One of the most striking things about ‘Let It Go’ is how successfully it has managed to stand on its own – how it has functioned, more or less, like a pop single. But would ‘Let It Go’ really be the cultural phenomenon it is if it was released in a universe where FROZEN didn’t exist? Where the song arrived without the context of Elsa and her great awakening, without her blue dress, power plait and giant ice palace? Our experts don’t think so.</p>

                            <p>“The way that the song goes with the film is very powerful,” says Wiley. “You see Elsa flinging her arms around, making ice come out of her hands and stamping on the floor and the floor becomes a snowflake. It’s the mirroring of the images and music – it cements it in our minds.</p>

                            <p>“It’s also the placement in the film. It’s the halfway point, it’s the turning point for Elsa. It’s part of her journey as a character. It’s just perfect for the final song of the first half of a musical theatre piece. It’s like ‘Defying Gravity’ in Wicked. It’s got that kind of power and strength to it.”</p>

                            <div className="compareImageWrapper">
                                <ReactCompareImage sliderLineColor="#8AECF5" sliderLineWidth={1} handleSize={0} sliderPositionPercentage={0.5} hover={true} leftImage={data.Image1.childImageSharp.gatsbyImageData.images.fallback.src} rightImage={data.Image2.childImageSharp.gatsbyImageData.images.fallback.src} />
                            </div>

                            <p>Hexel agrees.</p>

                            <p>“We’re getting this breakaway moment that suddenly feels oddly hopeful,” he says, “even though perhaps something quite bad and dark is happening. Yet it feels so empowering. From a storytelling perspective – and songwriters are storytellers – that moment is so well judged to say ‘we’re shining the spotlight on this character who is becoming so much more than we thought she could be’, which parallels nicely with her own experience of self-discovery. She’s saying ‘I’m finally going to be who I’m meant to be and I’m going to embrace who I am.’</p>

                            <div className="quoteWrapper">
                                <Quote>
                                    <div className="quoteContent">
                                        <h3>It’s also the placement in the film. It’s the halfway point, it’s the turning point for Elsa.</h3>
                                        <p className="color--navy">Dr Christopher Wiley </p>
                                    </div>
                                </Quote>
                            </div>

                            <p>“It must have been so tough for [Anderson-Lopez and Lopez] to figure out how to portray this complex character as somebody relatable. How does it feel to be outcast? How does it feel to be suddenly all on your own and yet find power within that? And to put that into music is a daunting task. I don’t know how many versions they would have gone through. What they’ve ended up with, though, is this hit song that encapsulates that moment so effectively. It’s something that’s difficult to plan. I’m a composer, right? And if I had to sit down and try to do that... It doesn’t just happen. A lot of stars have to align.”</p>
                        </div>
                    </Container>
                </Container>
            </TurningPoint>
        )}
    />
)

export default Query;