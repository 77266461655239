import React, {Component} from "react"
import {StaticQuery, graphql, Link} from "gatsby"
import {Row, Col, Container} from "reactstrap"
import styled from "styled-components"
import HeadingSpecial from "components/shared/HeadingSpecial"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {media} from "utils/Media"


import Lottie from 'react-lottie'
import * as animationData576 from 'bodymovin/let-it-go-576x826/data.json'
import * as animationData991 from 'bodymovin/let-it-go-991x1160/data.json'
import * as animationData1920 from 'bodymovin/let-it-go-1920x1080/data.json'

const Relative = styled.div`
    position: relative;
`

const BodymovinHeaderWrapper = styled.div`
    position: relative;
    margin-bottom: 1rem;
    margin-top: 0;
    pointer-events:none;
    
    @media ${media.sm} {
        margin-bottom: 2rem;
    }
    
    @media ${media.md} {
        margin-bottom: 3rem;
    }
    
    @media ${media.lg} {
        margin-top: 0;
        margin-bottom: 3rem;
    }
    
    &.flex {
        @media ${media.sm} {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }
    
    .HeadingSpecial {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 50px;
    }
`

const VideoContentWrapper = styled.div`
    width: 100%;
    
    //@media ${media.sm} {
        position: absolute;
        bottom: -20px;
        left: 0;
        z-index: 3;
    //}
    
    @media ${media.xl} {
        top: 0;
    }
`

const ImgBottom = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;   
`

const VideoContent = styled.div`
    width: 100%;
    max-width: 960px;
    margin-bottom: 16vw;
    
    @media ${media.sm} {
        color: ${props => props.theme.colors.white};
        margin-bottom: 3rem;
    }
    
    @media ${media.md} {
        position: absolute;
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 0;
    }
    
    @media ${media.lg} {
      
        bottom: 80px;
    }
    
    @media ${media.xl} {
        bottom: initial;
        top: 80px;
    }
    
    .btn {
        margin: 2rem auto 0;
    }
`

const BodymovinHeaderQuery = () => (
    <StaticQuery
        query={graphql`
            query {
                PlaceHolderXs: file(relativePath: { eq: "HomePage/bg-lig-header-placeholder-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                PlaceHolderSm: file(relativePath: { eq: "HomePage/bg-lig-header-placeholder-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                PlaceHolder: file(relativePath: { eq: "HomePage/bg-lig-header-placeholder-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
               
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
		`}
        render={data => (
            <BodymovinHeader data={data}/>
        )}
    />
)

class BodymovinHeader extends Component {

    render() {
        const letItGo576 = {
            loop: true,
            autoplay: true,
            renderer: 'svg',
            animationData: animationData576.default,
            assetsPath: "/images/"
        };

        const letItGo991 = {
            loop: true,
            autoplay: true,
            renderer: 'svg',
            animationData: animationData991.default,
            assetsPath: "/images/"
        };

        const letItGo1920 = {
            loop: true,
            autoplay: true,
            renderer: 'svg',
            animationData: animationData1920.default,
            assetsPath: "/images/"
        };
        return (
            <BodymovinHeaderWrapper>
                <div className="d-block d-sm-none">
                    <Lottie options={letItGo576}
                            className="img-fluid"
                    />
                </div>
                <div className="d-none d-sm-block d-lg-none">
                    <Lottie options={letItGo991}
                            className="img-fluid"
                    />
                </div>
                <div className="d-none d-lg-block">
                    <Lottie options={letItGo1920}
                            className="img-fluid"
                    />
                </div>

                <VideoContentWrapper>
                    <Relative className="flex">
                        <VideoContent>
                            {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeInUp">*/}
                                <HeadingSpecial lines={false} headingTag="h1" className="py-2 color--white shadow--navy"
                                                title="What makes ‘Let It Go’ <br class='d-none d-sm-inline-block d-lg-none'/>so catchy?"/>
                            {/*</ScrollAnimation>*/}
                        </VideoContent>
                    </Relative>
                </VideoContentWrapper>

                <ImgBottom>
                    <GatsbyImage image={this.props.data.ForegroundBottom.childImageSharp.gatsbyImageData} alt="" title=""
                         className="img-fluid"/>
                </ImgBottom>
            </BodymovinHeaderWrapper>
        )
    }
}

export default BodymovinHeaderQuery